<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('lucky_card.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('lucky_card.head2') }} </v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Bonus type and amount Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col class="pa-0">
                <v-card-title class="pb-2 px-3">Luck Card</v-card-title>
                <v-card-text class="px-3">{{ $t('lucky_card.head2_detail') }}</v-card-text>
              </v-col>

              <v-row class="ma-0">
                <v-col cols="12" class="d-flex flex-row align-center pa-0 mb-4">
                  <v-col cols="4" class="py-0"> {{ $t('lucky_card.type') }} </v-col>
                  <v-col cols="3" class="py-0"> {{ $t('lucky_card.amount') }} </v-col>
                  <v-col cols="2" class="py-0"> {{ $t('lucky_card.percent') }} </v-col>
                  <v-col cols="2" class="py-0"> {{ $t('lucky_card.multiply') }} </v-col>
                </v-col>

                <v-col cols="12" class="d-flex flex-row align-center pa-0" v-for="(i, n) in formData" :key="n">
                  <v-col cols="4" class="py-0">
                    <v-select :disabled="!canCreate || n === formData.length - 1" v-model="i.type" :items="rewardTypes"
                      :label="$t('lucky_card.type')" outlined dense></v-select>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field :disabled="!canCreate || n === formData.length - 1" v-model="i.amount"
                      :label="$t('lucky_card.amount')" type="number" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-text-field :disabled="!canCreate" v-model="i.rate" :label="$t('lucky_card.percent')" type="number"
                      outlined dense></v-text-field>
                  </v-col>
                  <v-col v-if="i.type === 'money'" cols="2" class="py-0">
                    <v-text-field :disabled="!canCreate" :label="$t('lucky_card.multiply')" v-model="i.multiply_turn_over"
                      type="number" placeholder="x เท่า" outlined dense></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="warning" @click="$router.push({ name: 'daily-login' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">ย้อนกลับ</span>
            </v-btn> -->
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('lucky_card.update') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      canCreate: false,
      formValid: true,
      formRule: {
        month: [value => !!value || this.$t('lucky_card.alert1'), value => this.validateMonthYear('month', value)],
        year: [value => !!value || this.$t('lucky_card.alert2'), value => this.validateMonthYear('year', value)],
        bonusAmount: [
          value => !!value || this.$t('lucky_card.alert3'),
          value => /^\d+$/.test(value) || this.$t('lucky_card.alert4'),
        ],
        type: [value => !!value || this.$t('lucky_card.alert5')],
        amount: [value => !!value || this.$t('lucky_card.alert3'), value => /^\d+$/.test(value) || this.$t('lucky_card.alert4')],
      },
      defaultData: [
        {
          type: null,
          amount: 0,
          rate: 0,
          multiply_turn_over: 0,
        },
        {
          type: null,
          amount: 0,
          rate: 0,
          multiply_turn_over: 0,
        },
        {
          type: null,
          amount: 0,
          rate: 0,
          multiply_turn_over: 0,
        },
        {
          type: null,
          amount: 0,
          rate: 0,
          multiply_turn_over: 0,
        },
        {
          type: null,
          amount: 0,
          rate: 0,
          multiply_turn_over: 0,
        },
      ],
      formData: this.defaultData,
      rewardTypes: ['point', 'coin', 'money'],

      breadcrumbsItems: [
        {
          text: this.$t('lucky_card.head'),
          disabled: false,
          href: '/luckyCard',
        },
        {
          text: this.$t('lucky_card.head_list'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()

    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getLuckyCardData()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Lucky Card',
              url: window.location.href,
              detail: 'รายละเอียด Lucky Card',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getLuckyCardData() {
      this.loading = true
      try {
        let res = await this.$store.dispatch('getLuckyCard')
        if (res && res.length > 0) {
          this.formData = res
          this.formData = res.map(item => {
            return {
              type: item.type,
              amount: item.amount,
              rate: item.rate,
              multiply_turn_over: item.multiply_turn_over,
            }
          })
        } else {
          this.formData = this.defaultData
        }

        this.loading = false
      } catch (e) { }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'luckyCard')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
      }
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('lucky_card.alert_update'),
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('confrim'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addLuckyCardData()
            }
          })
      }
    },
    async addLuckyCardData() {
      this.loading = true
      this.formData = this.formData.map(item => {
        return {
          type: item.type,
          amount: item.amount,
          rate: item.rate,
          multiply_turn_over: item.type === 'money' ? item.multiply_turn_over : 0,
        }
      })
      let formData = { cards: this.formData }
      try {
        await this.$store.dispatch('addLuckyCard', formData)
        this.$swal.fire(this.$t('lucky_card.alert_complete'), '', 'success').then(action => {
          window.location.reload()
        })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
  },
  computed: {},
  watch: {},
}
</script>
